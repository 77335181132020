/* eslint-disable no-mixed-operators */
/* eslint-disable no-undef */
/* eslint-disable semi */
/* eslint-disable space-before-function-paren */

// eslint-disable-next-line no-unused-vars
const GlfxInterface = new MooTools.Class({

  mCanvas: null,

  mCache: {},

  initialize: function (pImage, fn) {
    this.mCanvas = this.createCanvasEl(pImage);
    this.mTexture = this.mCanvas.texture(pImage);
    // eslint-disable-next-line no-unused-expressions
    fn && fn(this, this.mCanvas);
  },

  createCache: function (pProperties) {
    _.each(pProperties, function (pValue, pProperty) {
      this.addToCache(pProperty, pValue);
    }.bind(this));
  },

  createCanvasEl: function (image) {
    const canvas = fx.canvas();
    canvas.setAttribute('style', image.getAttribute('style'));
    canvas.setAttribute('class', image.getAttribute('class'));
    return canvas;
  },

  updateTexture: function (pElement) {
    if (pElement) {
      this.mTexture = this.mCanvas.texture(pElement);
    }
    this.mCanvas.draw(this.mTexture);
    this.mCanvas.update();
  },

  addToCache: function (pProperty, pValue) {
    switch (pProperty) {
      case 'brightness':
      case 'contrast':
        this.mCache[pProperty] = 0.6 * pValue / 100;
        break;
      case 'blur':
        this.mCache[pProperty] = parseInt(20 * pValue / 100, 10);
        break;
      case 'sharpen':
        this.mCache[pProperty] = 5 * pValue / 100;
        break;
      default:
        this.mCache[pProperty] = pValue / 100;
    }

  // eslint-disable-next-line padded-blocks
  },

  initializeRender: function (pProperties) {
    this.createCache(pProperties);
    this.render();
  },

  render: function () {
    const texture = this.mCanvas.draw(this.mTexture);

    if (this.mCache.brightness || this.mCache.contrast) {
      texture.brightnessContrast(this.mCache.brightness, this.mCache.contrast)
    }

    if (this.mCache.vibrance) {
      texture.vibrance(this.mCache.vibrance)
    }

    if (this.mCache.saturation) {
      texture.hueSaturation(0, this.mCache.saturation)
    }

    if (this.mCache.blur) {
      texture.triangleBlur(this.mCache.blur)
    }

    if (this.mCache.sharpen) {
      texture.unsharpMask(2, this.mCache.sharpen)
    }

    texture.update();
  },

  renderProperty: function (pProperty, pValue) {
    this.addToCache(pProperty, pValue);
    this.render();
  },

  reset: function () {
    _.each(this.mCache, function (pValue, pProperty) {
      this.mCache[pProperty] = 0;
    }.bind(this));
    this.render();
  },

  toData: function() {
    return this.mCanvas.toDataURL('image/png');
  },

  destroy: function () {
    this.mTexture.destroy();
  // eslint-disable-next-line comma-dangle
  }

});
